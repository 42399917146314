import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../store/store";
import AnswerGPT from "../answer/answer";
import Questions from "../questions/questions";
import s from "./contentBlockSubCategoryV2Mobile.module.css";
import { CategoryType } from "../../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ANSWER_EDIT_DELAY } from "../../../store/storeConst/consts";
import { getQuestionsThunk } from "../../../store/features/questionThunk";
import { Loader } from "../../../components/loader/loader";

const ContentBlockSubCategoryV2Mobile = () => {
  const dispatch = useAppDispatch();
  const param = useParams();
  const { categoryId } = param || "";
  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );
  useEffect(() => {
    dispatch(getQuestionsThunk({ categoryId: categoryId! }));
  }, []);
  const categoryTitle: string | undefined = categories.find(
    (cat) => cat.id === categoryId
  )?.title;
  const currentCategory: CategoryType | undefined = categories.find(
    (cat) => cat.id === categoryId
  );
  const categoryDescription: string | undefined = categories.find(
    (cat) => cat.id === categoryId
  )?.description;

  const [isShowQuestions, setIsShowQuestions] = useState<boolean>(true);
  const [isDelayTimeout, setIsDelayTimeout] = useState<boolean>(false);

  const onSwitcherQuestionsFieldClickHandler = () => {
    setIsShowQuestions(true);
  };
  const onSwitcherAnswersFieldClickHandler = () => {
    setIsDelayTimeout(true);
    setTimeout(() => {
      setIsDelayTimeout(false);
      setIsShowQuestions(false);
    }, ANSWER_EDIT_DELAY);
  };

  return (
    <div className={s.contentBlockSubCategoryV2Mobile_wrapper}>
      <div className={s.subCatTitleDiv}>
        <div>
          <h3>{categoryTitle}</h3>
          <label>{categoryDescription}</label>
          <div className={s.subCatDescriptionNotice}>
            * Если первая версия текста вас не устроила – повторяйте генерацию
            до нужного результата. Обычно требуется 2-4 нажатия. Количество
            генераций – не ограничено!
          </div>
        </div>
      </div>

      <div className={s.contentSwitcher_class}>
        <div
          className={
            isShowQuestions
              ? s.switcher_option + " " + s.switcher_option_selected
              : s.switcher_option
          }
          onClick={onSwitcherQuestionsFieldClickHandler}
        >
          <div>Этап 1:</div>
          <div>
            <b>Заполнение данных</b>
          </div>
        </div>
        <div
          className={
            !isShowQuestions
              ? s.switcher_option + " " + s.switcher_option_selected
              : s.switcher_option
          }
          onClick={onSwitcherAnswersFieldClickHandler}
        >
          <div>Этап 2:</div>
          <div>
            <b>Получение ответа</b>
          </div>
        </div>
      </div>

      {isDelayTimeout && <Loader />}

      {isShowQuestions ? (
        <Questions
          // type_gpt={currentCategory!.type_gpt}
          // mobileSwitch={onSwitcherAnswersFieldClickHandler}
        />
      ) : (
        <AnswerGPT type_gpt={currentCategory!.type_gpt} />
      )}
    </div>
  );
};

export default ContentBlockSubCategoryV2Mobile;
