import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { questionInitContent } from "../storeConst/vars";
import {
  CategoryType,
  CategoryTypeGpt,
  ChangingOneQuestionType,
  QuestionSliceInitType,
  QuestionType,
  TemplateType,
} from "./questionTypes";
import {
  deleteTemplateThunk,
  getCategoriesThunk,
  GetQuestionsResponse_V2_Type,
  getQuestionsThunk,
  getTemplatesThunk,
  getTemplatesThunk_ResponseType,
  postAddTemplateThunk,
} from "./questionThunk";
import { toast } from "react-toastify";
import { OptionType } from "../../components/select/Select";

export const questionSlice = createSlice({
  name: "questionSlice",
  initialState: questionInitContent,
  reducers: {
    tempChangeQuestionsAC: (
      state: QuestionSliceInitType,
      action: PayloadAction<Array<QuestionType>>
    ) => {
      state.questions = action.payload;
    },
    setNoticeNewAnswerGPT: (
      state: QuestionSliceInitType,
      action: PayloadAction<{ id: string; value: boolean }>
    ) => {
      let parentCategoryId = "";
      state.categories = state.categories.map((c) => {
        if (c.id === action.payload.id) {
          parentCategoryId = c.parentId!;
          return { ...c, isNotViewedAnswer: action.payload.value };
        }
        return c;
      });
      state.categories = state.categories.map((c) => {
        if (parentCategoryId === c.id) {
          return { ...c, isNotViewedAnswer: action.payload.value };
        }
        return c;
      });
    },
    changeShowTemplateQuestionsListAC: (
      state: QuestionSliceInitType,
      action: PayloadAction<Array<string>>
    ) => {
      state.templateVars.showTemplateQuestionsList = action.payload;
    },
    changeOneAnswerInQuestionAC: (
      state: QuestionSliceInitType,
      action: PayloadAction<ChangingOneQuestionType>
    ) => {
      state.questions = [...state.questions].map((question) => {
        if (question.id === action.payload.question_id) {
          return { ...question, answer: action.payload.answer };
        }
        return question;
      });
    },
    changeCurrentGptTypeAC: (state: QuestionSliceInitType, action: PayloadAction<CategoryTypeGpt>) => {
      state.QuestionSliceVars.currentGptType = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getCategoriesThunk.pending,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isCategoryLoading = true;
      }
    );
    builder.addCase(
      getCategoriesThunk.fulfilled,
      (
        state: QuestionSliceInitType,
        action: PayloadAction<Array<CategoryType>>
      ) => {
        state.categories = action.payload;
        state.loadingVars.isCategoryLoading = false;
      }
    );
    builder.addCase(
      getCategoriesThunk.rejected,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isCategoryLoading = false;
      }
    );

    //----------------------------

    builder.addCase(
      getQuestionsThunk.pending,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isQuestionsLoading = true;
      }
    );
    builder.addCase(
      getQuestionsThunk.fulfilled,
      (
        state: QuestionSliceInitType,
        action: PayloadAction<GetQuestionsResponse_V2_Type>
      ) => {
        if (action.payload && action.payload.payloads.length > 0) {
          state.questions = action.payload.payloads.map((elem) => {
            return {
              id: elem.id,
              questionType: elem.question_type,
              question: elem.question,
              snippet: elem.snippet,
              options: elem.options,
              answer: elem.answer,
              isRequired: elem.is_required,
              categoryId: "",
              order_index: elem.order_index,
            };
          });
        } else {
          state.questions = [];
        }
        state.models = action.payload.models.map(
          (m) => ({ title: m.type_title, value: m.model_id } as OptionType)
        );

        state.loadingVars.isQuestionsLoading = false;
      }
    );
    builder.addCase(
      getQuestionsThunk.rejected,
      (state: QuestionSliceInitType) => {
        state.questions = [];
        state.loadingVars.isQuestionsLoading = false;
      }
    );

    //----------------------------

    builder.addCase(
      getTemplatesThunk.pending,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isTemplatesLoading = true;
      }
    );
    builder.addCase(
      getTemplatesThunk.fulfilled,
      (
        state: QuestionSliceInitType,
        action: PayloadAction<Array<getTemplatesThunk_ResponseType>>
      ) => {
        if (action.payload) {
          state.templates = action.payload.map((elem) => {
            const item: TemplateType = {
              id: elem.id,
              title: elem.title,
              categoryId: elem.data.category_id,
              questions: [...state.questions].map((q) => {
                const answers: Array<ChangingOneQuestionType> =
                  elem.data.answers;
                const t = answers.find((e) => e.question_id === q.id);
                if (t) {
                  return {
                    ...q,
                    answer: t.answer === "None" ? "" : t.answer,
                  };
                }
                return q;
              }),
            };
            return item;
          });
        }

        state.loadingVars.isTemplatesLoading = false;
      }
    );
    builder.addCase(
      getTemplatesThunk.rejected,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isTemplatesLoading = false;
      }
    );

    //----------------------------

    builder.addCase(
      deleteTemplateThunk.pending,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isTemplatesLoading = true;
      }
    );
    builder.addCase(
      deleteTemplateThunk.fulfilled,
      (
        state: QuestionSliceInitType,
        action: PayloadAction<Array<TemplateType>>
      ) => {
        if (action.payload) {
          state.templates = action.payload;
        }
        state.loadingVars.isTemplatesLoading = false;
      }
    );
    builder.addCase(
      deleteTemplateThunk.rejected,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isTemplatesLoading = false;
      }
    );

    //----------------------------

    builder.addCase(
      postAddTemplateThunk.pending,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isTemplatesLoading = true;
      }
    );
    builder.addCase(
      postAddTemplateThunk.fulfilled,
      (state: QuestionSliceInitType, action: PayloadAction<any>) => {
        if (action.payload.status === "ok") {
          toast.success("Шаблон добавлен!");
        }
        if (action.payload.status === "fail") {
          toast.success("Ошибка!");
        }
        state.loadingVars.isTemplatesLoading = false;
      }
    );
    builder.addCase(
      postAddTemplateThunk.rejected,
      (state: QuestionSliceInitType) => {
        state.loadingVars.isTemplatesLoading = false;
      }
    );
  },
});
export const {
  tempChangeQuestionsAC,
  changeShowTemplateQuestionsListAC,
  changeOneAnswerInQuestionAC,
  setNoticeNewAnswerGPT,
  changeCurrentGptTypeAC,
} = questionSlice.actions;

export default questionSlice.reducer;
