import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChangingOneQuestionType } from "../questionTypes";
import { changeIsLimitationOnAC, removeWhichCategoryGptLoadingAC } from "./answerSlice";
import { setNoticeNewAnswerGPT } from "../questionSlice";
import { profileActions } from "../profile/profile-slice";
import { toast } from "react-toastify";
import { apiErrorCodes, standartApiErrorMessage } from "../../../common/api/errorCodes";
import { chatGptResponseApi, ChatGptSendThunk_ApiType } from "../../../common/api/chat-gpt-api/chat-gpt-api";

export type chatGptSendThunk_DataType = {
    question_answer: Array<ChangingOneQuestionType>;
    category_id: {
        model_gpt_id: string;
        categoryId: string;
    };
};

export type PostReceiveGptResponseV2Type = {
    gpt_answer: string;
    gpt_answer_id: string;
    categoryId: string;
};

export const chatGptSendThunk = createAsyncThunk(
    "answer/chatGptSendThunk",
    async (data: chatGptSendThunk_DataType, { rejectWithValue, dispatch }) => {
      try {
        const dataToSend: ChatGptSendThunk_ApiType = {
          ...data,
          question_answer: [...data.question_answer].map((q) => {
            return {
              ...q,
              answer: q.answer && q.answer.length > 0 ? q.answer : null,
            };
          }),
        };
        
        const res = await chatGptResponseApi.getGptResponseV2(dataToSend);
        dispatch(removeWhichCategoryGptLoadingAC(data.category_id.categoryId));
        dispatch(
          setNoticeNewAnswerGPT({ id: data.category_id.categoryId, value: true })
        );
        dispatch(
          profileActions.setRemainingUses(res.data.payloads.gpt_answer.length)
        );
        return { ...res.data.payloads, categoryId: data.category_id.categoryId };
        
      } catch (err: any) {
        dispatch(removeWhichCategoryGptLoadingAC(data.category_id.categoryId));
        if (err.response.status === 400) {
          toast.error("Не все ответы предоставлены");
        } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
          await dispatch(chatGptSendThunk(data));
        } else if (
          err.response.data.message === "Bot send message notification error"
        ) {
          toast.error(
            "Доступ к данной категории возможен только с оплаченным тарифом"
          );
          dispatch(changeIsLimitationOnAC(true));
        } else if (
          err.response.status === apiErrorCodes.categoryHasNotPaid
        ) {
          toast.error(
            "Доступ к данной категории возможен только с оплаченным тарифом. Либо у Вас не оплачен тариф, либо Ваш тариф не включает данную категорию."
          );
        } else {
          standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
        }
      }
    }
  );